import React from "react";
import { Gallery, Image } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

export interface CustomImage extends Image {
  original: string;
}

interface IProps {
  images: CustomImage[];
}

const GalleryDocuments: React.FC<IProps> = ({ images }) => {
  const [open, setOpen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0); // Estado para o índice atual

  const handleClick = (index: number) => {
    setCurrentIndex(index); // Atualiza o índice da imagem clicada
    setOpen(true); // Abre o lightbox
  };

  return (
    <div>
      <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
      />

      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={images.map((image) => ({
          src: image.original,
          title: image.caption,
        }))}
        index={currentIndex} // Passa o índice atual
        plugins={[Zoom]} // Adicionando o plugin de zoom
        zoom={{ scrollToZoom: true, maxZoomPixelRatio: 10 }}
      />
    </div>
  );
};

export { GalleryDocuments };
