import styled from "styled-components";

export const Odontograma = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  svg {
    width: 35px;
    height: 90px;
  }
`;

export const Superiores = styled.div`
  display: flex;
  gap: 6px;
  padding-bottom: 20px;
`;

export const Inferiores = styled.div`
  display: flex;
  gap: 6px;
  border-top: 3px solid #000;
  padding-top: 20px;
`;

export const BtnFixed = styled.div`
  position: fixed;
  bottom: 15px;
  width: 93%;
`;

export const Box = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  svg {
    :first-child {
      fill: ${(props) =>
        props.selected ? "#007DED !important" : "black !important"};
    }
  }

  p {
    font-size: 18px;
    color: ${(props) => (props.selected ? "#007DED" : "#000")};
    font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  }
`;

export const BoxScroll = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;
