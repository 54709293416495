import React from "react";

import { Schedule } from "shared/types/Schedule";
interface IProps {
  children: any;
}

export interface IClinicalContext {
  patientObj: Schedule;
}

const ClinicalContext = React.createContext({} as IClinicalContext);

const ClinicalProvider: React.FC<IProps> = ({ children }) => {
  const patientActually = localStorage.getItem("personAttendance");

  const patientObj = JSON.parse(patientActually as string);

  const value = React.useMemo(
    () => ({
      patientObj,
    }),
    [patientObj]
  );

  return (
    <ClinicalContext.Provider value={value}>
      {children}
    </ClinicalContext.Provider>
  );
};

const useClinical = (): IClinicalContext => React.useContext(ClinicalContext);

export { ClinicalContext, ClinicalProvider, useClinical };
