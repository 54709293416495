/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import * as Styled from "./styles";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { ReturnButton } from "components/ReturnButton";
import { useAuth } from "shared/providers/auth";
import { useParams } from "react-router-dom";
import { getClinicalRecords } from "requests/ClinicalRecord";
import { ClinicalRecord } from "shared/types/ClinicalRecord";

import { IoIosArrowUp } from "react-icons/io";

interface WireDescriptions {
  [key: number]: string;
}

const typeMap: Record<number, string> = {
  55: "Colagem Inferior",
  56: "Colagem Superior",
  2: "1º Consulta",
  285: "Colagem de Tubo",
  30: "Banda Inferior",
  31: "Banda Superior",
  7: "Alta",
  201: "Remoção de Aparelho",
  136: "Manutenção",
  127: "Instalação Aparelho Móvel Inferior",
  128: "Instalação Aparelho Móvel Superior",
  492: "Instalacao de aparelho movel",
  144: "Moldagem Inferior",
  146: "Moldagem Superior",
  493: "Moldagem do Aparelho",
  491: "Manutenção",
  65: "Manutenção",
  490: "Manutenção",
  134: "Manutenção",
  138: "Manutenção",
  440: "Manutenção",
  266: "Manutenção",
  244: "Separador",
};

const ClinicalRecordUser: React.FC<{
  idPacientParam?: number;
  onClick?: () => void;
}> = ({ idPacientParam, onClick }) => {
  const { user } = useAuth();
  const { idPacient } = useParams();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [records, setRecords] = React.useState<ClinicalRecord[]>([]);

  const clinicalRecordsAll = async () => {
    setLoading(true);

    try {
      setLoading(false);
      const { status, data } = await getClinicalRecords({
        userId: user.data.id,
        idPac: Number(
          idPacientParam && idPacientParam > 0 ? idPacientParam : idPacient
        ),
      });

      if ([200, 201].includes(status)) {
        setRecords(data.data);
      } else {
        throw new Error("Erro ao carregar as fotos.");
      }
    } catch (e: any) {
      setLoading(false);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    clinicalRecordsAll();
  }, []);

  const getDescription = (code: string) => {
    if (code === "PPQ001") {
      return "Colagem da Aparatologia Ortodôntica";
    }
    if (code === "PPQ002") {
      return "Trocar Fio";
    }
    if (code === "PPQ003") {
      return "Manter Fio";
    }
    if (code === "PPQ004") {
      return "Cimentação de banda";
    }
    if (code === "PPQ005") {
      return "Colagem do tubo";
    }
    if (code === "PPQ007") {
      return "Aparelho Complementar";
    }
    if (code === "PPQ008") {
      return "Outros procedimentos";
    }
    if (code === "PPQ009") {
      return "Encaminhamentos";
    }
    if (code === "PPQ015") {
      return "Aparelho Móvel";
    }
    if (code === "PPQ016") {
      return "Realizou a moldagem";
    }
    return null;
  };

  const positionDescriptions = {
    0: "Inferior",
    1: "Superior",
    3: "Superior e Inferior",
  };

  const typeDescriptions: WireDescriptions = {
    1: "12 Redondo",
    2: "14 Redondo",
    3: "16 Redondo",
    4: "18 Redondo",
    5: "20 Redondo",
    6: "16x22 Retangular",
    7: "17x25 Retangular",
    8: "18x25 Retangular",
    9: "19x25 Retangular",
  };

  const wireDescriptions: WireDescriptions = {
    1: "Niti",
    2: "Aço",
    3: "Niti Curva Reversa",
    4: "Aço Curva Reversa",
  };

  const transformedData = [
    { label: "Faz uso de medicamento?", identify: "OCQ13" },
    { label: "Anomalia de nascença?", identify: "OCQ14" },
    { label: "Cirurgia anterior?", identify: "OCQ15" },
    { label: "A recuperação ocorreu como esperado?", identify: "OCQ25" },
    { label: "Teve Hemorragia?", identify: "OCQ26" },
    { label: "Já foi anestesiado?", identify: "OCQ16" },
    { label: "Distúrbios respiratórios?", identify: "OCQ17" },
    { label: "Doenças sanguíneas?", identify: "OCQ18" },
    { label: "Doença óssea?", identify: "OCQ19" },
    { label: "Problemas endócrinos?", identify: "OCQ20" },
    { label: "Doenças cardíacas?", identify: "OCQ21" },
    { label: "Possui alguma alergia?", identify: "OCQ22" },
    { label: "Traumatismo de face ou cabeça?", identify: "OCQ23" },
    { label: "Fraturas ósseas?", identify: "OCQ24" },
    { label: "A recuperação aconteceu como esperado?", identify: "OCQ27" },

    // Adicionando as opções
    { label: "Asma", identify: "OCQ1" },
    { label: "Anemia", identify: "OCQ2" },
    { label: "Febre reumática", identify: "OCQ3" },
    { label: "Epilepsia", identify: "OCQ4" },
    { label: "Distúrbios auditivos", identify: "OCQ5" },
    { label: "Herpes", identify: "OCQ6" },
    { label: "Hepatite", identify: "OCQ7" },
    { label: "Diabete", identify: "OCQ8" },
    { label: "HIV", identify: "OCQ9" },
    { label: "Gestante", identify: "OCQ10" },
    { label: "Fumante", identify: "OCQ11" },
    { label: "Hipertensão", identify: "OCQ12" },
  ];

  const formatDateTime = (dateTimeString: string): string => {
    const dateTime: Date = new Date(dateTimeString);
    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    const formattedDateTime: string = dateTime.toLocaleDateString(
      "pt-BR",
      options
    );

    const today: Date = new Date();

    // Verifica se a data é hoje
    if (dateTime.toDateString() === today.toDateString()) {
      return `Hoje, ${formattedDateTime.split(",")[1]}`;
    }
    // Verifica se a data é ontem
    else if (
      dateTime.toDateString() ===
      new Date(today.getTime() - 86400000).toDateString()
    ) {
      return `Ontem, ${formattedDateTime.split(",")[1]}`;
    }
    // Para outras datas
    else {
      return formattedDateTime;
    }
  };

  const getTypeDescription = (typeId: number): string => {
    const description = typeMap[typeId];
    return description ? description : "Descrição não encontrada";
  };

  const getLabelById = (id: string) => {
    const item = transformedData.find((entry) => entry.identify === id);
    return item ? item.label : id; // Retorna o id se não encontrar o label
  };

  return (
    <Container maxWidth="xl">
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <ReturnButton title="Ficha Clínica" onClick={onClick} />

          <Styled.BoxScroll>
            {records.length > 0 ? (
              records.map((record, index) => (
                <>
                  <Accordion key={index} sx={{ marginTop: "30px" }}>
                    <AccordionSummary
                      expandIcon={<IoIosArrowUp />}
                      aria-controls={String(record.idRecord)}
                      id={String(record.idRecord)}
                      sx={{ fontWeight: "bold" }}
                    >
                      {record.appointment}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Styled.Box>
                        {record.doc &&
                        record.doc.diagnosis &&
                        record.doc.diagnosis.length > 0 ? (
                          <>
                            <Styled.BoxClinical>
                              <Typography variant="h5">Diagnóstico</Typography>
                              {record.doc.diagnosis.map(
                                (item: any, index: number) => (
                                  <React.Fragment key={index}>
                                    <Typography>
                                      {item.question}
                                      {item.description &&
                                        `: ${item.description}`}
                                    </Typography>
                                  </React.Fragment>
                                )
                              )}
                            </Styled.BoxClinical>
                          </>
                        ) : (
                          ""
                        )}

                        {record.doc &&
                        record.doc.anamnese &&
                        record.doc.anamnese.length > 0 ? (
                          <>
                            <Styled.BoxClinical>
                              <Typography variant="h5">Anamnese</Typography>

                              {record.doc.anamnese.map(
                                (item: any, index: any) => (
                                  <React.Fragment key={index}>
                                    <Typography
                                      sx={{ fontWeight: "bold !important" }}
                                    >
                                      {getLabelById(item.id)}
                                    </Typography>

                                    <Typography>
                                      {item.option === 1 ? "Sim" : "Não"}
                                    </Typography>

                                    {item.description && (
                                      <Typography>
                                        Observação: {item.description}
                                      </Typography>
                                    )}
                                  </React.Fragment>
                                )
                              )}
                            </Styled.BoxClinical>
                          </>
                        ) : (
                          ""
                        )}

                        {record.doc &&
                        record.doc.procedures &&
                        record.doc.procedures.length > 0 ? (
                          <>
                            <Styled.BoxClinical>
                              <Typography variant="h5">
                                Procedimentos Realizados
                              </Typography>

                              {record.doc.procedures.map(
                                (item: any, index: number) =>
                                  item.type !== "NOT" ? (
                                    <Styled.BoxItens key={index}>
                                      {item &&
                                        typeof item.question === "string" && (
                                          <Typography
                                            sx={{
                                              fontWeight: "bold !important",
                                            }}
                                          >
                                            {item.question.replace(/\?/g, "")}
                                          </Typography>
                                        )}

                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        {item &&
                                          item.description &&
                                          (Array.isArray(item.description) ? (
                                            item.description.length > 0 && (
                                              <>
                                                {item.description.map(
                                                  (desc: any, index: any) => (
                                                    <Typography key={index}>
                                                      {desc &&
                                                      typeof desc === "string"
                                                        ? desc
                                                        : desc?.label}
                                                    </Typography>
                                                  )
                                                )}
                                              </>
                                            )
                                          ) : (
                                            <Typography>
                                              {item.description}
                                            </Typography>
                                          ))}
                                      </Box>

                                      {item && item.realize && (
                                        <Typography>{item.realize}</Typography>
                                      )}

                                      {item && item.type && (
                                        <Typography>{item.type}</Typography>
                                      )}

                                      {item.metaData?.wire && (
                                        <Typography>
                                          Material: {item.metaData.wire}
                                        </Typography>
                                      )}

                                      {item.metaData?.type && (
                                        <Typography>
                                          Número: {item.metaData.type}
                                        </Typography>
                                      )}
                                    </Styled.BoxItens>
                                  ) : null // Usando null ao invés de "" para não renderizar nada
                              )}

                              {record.doc &&
                                record.doc.planningPreviousConsultation &&
                                record.doc.planningPreviousConsultation.map(
                                  (item: any, index: any) => {
                                    let descriptionData = [];
                                    if (item.description) {
                                      try {
                                        descriptionData = JSON.parse(
                                          item.description
                                        );
                                      } catch (error) {}
                                    }

                                    return (
                                      <Styled.BoxItens key={index}>
                                        <Typography
                                          sx={{ fontWeight: "bold !important" }}
                                        >
                                          {getDescription(item.id)}
                                        </Typography>

                                        {item.position !== undefined && (
                                          <Typography>
                                            {item.position === 0
                                              ? "Inferior"
                                              : item.position === 1
                                              ? "Superior"
                                              : item.position === 3
                                              ? "Superior e Inferior"
                                              : ""}
                                          </Typography>
                                        )}

                                        {item.metaData?.wire && (
                                          <Typography>
                                            Material:{" "}
                                            {wireDescriptions[
                                              item.metaData.wire
                                            ] || ""}
                                          </Typography>
                                        )}

                                        {item.metaData?.type && (
                                          <Typography>
                                            Número:{" "}
                                            {typeDescriptions[
                                              item.metaData.type
                                            ] || ""}
                                          </Typography>
                                        )}

                                        {descriptionData.length > 0 && (
                                          <>
                                            {descriptionData.map(
                                              (
                                                descItem: any,
                                                descIndex: any
                                              ) => (
                                                <Typography key={descIndex}>
                                                  {descItem.label}
                                                </Typography>
                                              )
                                            )}
                                          </>
                                        )}
                                      </Styled.BoxItens>
                                    );
                                  }
                                )}
                            </Styled.BoxClinical>
                          </>
                        ) : (
                          ""
                        )}

                        <Styled.BoxClinical>
                          <Typography variant="h5">
                            Planejamento da próxima consulta
                          </Typography>

                          {record.nextAppointment.map((item, index) => {
                            return (
                              <Styled.BoxItens key={index}>
                                <Typography
                                  sx={{ fontWeight: "bold !important" }}
                                >
                                  {getDescription(item.id)}
                                </Typography>

                                {(item.position === 0 ||
                                  item.position === 1 ||
                                  item.position === 3) && (
                                  <Typography key={item.id}>
                                    {positionDescriptions[item.position]}
                                  </Typography>
                                )}

                                {typeof item.metaData === "object" &&
                                  !Array.isArray(item.metaData) && (
                                    <>
                                      {item.metaData.wire !== undefined && (
                                        <Typography>
                                          Material:{" "}
                                          {wireDescriptions[
                                            item.metaData.wire
                                          ] || ""}
                                        </Typography>
                                      )}

                                      {item.metaData.type !== undefined && (
                                        <Typography>
                                          Número:{" "}
                                          {typeDescriptions[
                                            item.metaData.type
                                          ] || ""}
                                        </Typography>
                                      )}
                                    </>
                                  )}

                                {Array.isArray(item.metaData) &&
                                  item.metaData.length > 0 && (
                                    <>
                                      {item.metaData.map(
                                        (descItem, descIndex) => (
                                          <Typography key={descIndex}>
                                            {descItem.label}
                                          </Typography>
                                        )
                                      )}
                                    </>
                                  )}
                              </Styled.BoxItens>
                            );
                          })}
                        </Styled.BoxClinical>

                        {record.doc &&
                          record.doc.schedule &&
                          record.doc.schedule.length > 0 &&
                          record.doc.schedule.map((item: any, index: any) => (
                            <Styled.BoxClinical key={index}>
                              <Typography variant="h5">
                                Agendamento -{" "}
                                {getTypeDescription(item.procedure)}
                              </Typography>
                              <Typography>
                                {formatDateTime(item.date)}
                              </Typography>
                            </Styled.BoxClinical>
                          ))}

                        <Styled.BoxClinical>
                          <Typography variant="h5">
                            Assinatura do Dentista
                          </Typography>
                          <Styled.BoxImage src={record.dentSignature} />
                        </Styled.BoxClinical>

                        <Styled.BoxClinical>
                          <Typography variant="h5">
                            Assinatura do Paciente
                          </Typography>
                          <Styled.BoxImage src={record.pacSignature} />
                        </Styled.BoxClinical>
                      </Styled.Box>
                    </AccordionDetails>
                  </Accordion>
                </>
              ))
            ) : (
              <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
                  Não há ficha clínica para este paciente.
                </Typography>
              </Box>
            )}
          </Styled.BoxScroll>
        </>
      )}
    </Container>
  );
};

export { ClinicalRecordUser };
