import styled from "styled-components";

export const ContainerPictures = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 80vh;
  overflow: scroll;
  margin-top: 25px;

  a {
    color: #000;
  }
`;

export const BoxDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h5 {
    font-size: 18px;
    font-weight: bold;
    color: #343536;
  }
`;
