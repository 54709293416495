import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  //margin-top: 25px;
  padding: 0 1px;

  @media print {
    height: auto;
    overflow: visible;
  }
`;

export const BoxDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h5 {
    font-size: 18px;
    font-weight: bold;
    color: #343536;
  }
`;

export const BoxIndication = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid #eeeff1;
  padding: 5px 15px;
  h5 {
    font-size: 18px;
    font-weight: bold;
    color: #343536;
  }
`;

export const BoxButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;

  gap: 20px;
`;

export const BtnFixed = styled.div`
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
`;

export const AllComplements = styled.div`
  background-color: #f7f8fa;
  padding: 5px 15px;
  margin: 5px 0;
`;

export const ContentComplement = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
`;

export const BraceType = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;
`;

export const BoxClinical = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #343536;
  padding: 20px;
  gap: 20px;
  h5 {
    font-weight: bold;
  }

  p {
    font-weight: 500;
    padding: 5px 0;
  }
`;

export const BoxClinicalSignature = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #343536;

  padding: 20px;
  gap: 20px;

  .boxSignature {
    border: 1px solid #c5c5c5;
    width: 100%;
    .sigCanvas {
      width: 100% !important;
    }
  }
  h5 {
    font-weight: bold;
    padding: 15px;
  }

  p {
    font-weight: 500;
    padding: 5px 0;
  }
`;

export const BoxSave = styled.div`
  padding: 10px;
  background-color: #007ded;
  width: 80px;
  color: white;
  text-align: center;
  border-radius: 5px;
`;

export const BoxSaveOrto = styled.div`
  padding: 10px;
  background-color: #007ded;
  width: 80px;
  color: white;
  text-align: center;
  border-radius: 5px;
`;

export const BoxSaveOrtoFake = styled.div`
  padding: 10px;
  background-color: transparent;
  width: 80px;
  color: white;
  text-align: center;
  border-radius: 5px;
`;

export const BoxProcedures = styled.div`
  background-color: #f7f8fa;
  padding: 10px;

  display: flex;
  flex-direction: column;

  :first-child {
    font-weight: bold;
  }
`;
