import { Box, Button, CircularProgress } from "@mui/material";
import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { sendClinical } from "requests/Clinical";
import { ROUTER } from "shared/constants/router";
import { useAuth } from "shared/providers/auth";
import { useClinical } from "shared/providers/Clinical";
import { HeadConsult } from "./Head";

const Finalize: React.FC = () => {
  const { patientObj } = useClinical();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);

  const send = async () => {
    setLoading(true);

    const { status, data } = await sendClinical({
      id: user.data.id,
      idPac: patientObj.pac_id,
      idSchedule: patientObj.id,
    });

    if ([200, 201].includes(status)) {
      toast.success(data.message);
      navigate(ROUTER.HOME);
      setLoading(false);
      localStorage.removeItem("personAttendance");
    }
    setLoading(false);
  };

  return (
    <>
      <HeadConsult title="Clínico Geral" />
      <Box
        sx={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          marginTop: "4rem",
        }}
      >
        <Button fullWidth onClick={send} disabled={loading}>
          {loading ? <CircularProgress color="inherit" /> : "Finalizar"}
        </Button>
      </Box>
    </>
  );
};

export { Finalize };
