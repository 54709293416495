/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import * as Styled from "./styles";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { ReturnButton } from "components/ReturnButton";
import { useAuth } from "shared/providers/auth";
import { useParams } from "react-router-dom";
import { getClinicalRecords } from "requests/ClinicalRecord";
import { ClinicalRecord } from "shared/types/ClinicalRecord";

import { IoIosArrowUp } from "react-icons/io";

interface WireDescriptions {
  [key: number]: string;
}

const TreatmentPlan: React.FC<{
  idPacientParam?: number;
  onClick?: () => void;
}> = ({ idPacientParam, onClick }) => {
  const { user } = useAuth();
  const { idPacient } = useParams();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [records, setRecords] = React.useState<ClinicalRecord[]>([]);

  const clinicalRecordsAll = async () => {
    setLoading(true);

    try {
      setLoading(false);
      const { status, data } = await getClinicalRecords({
        userId: user.data.id,
        idPac: Number(
          idPacientParam && idPacientParam > 0 ? idPacientParam : idPacient
        ),
      });

      if ([200, 201].includes(status)) {
        setRecords(data.data);
      } else {
        throw new Error("Erro ao carregar as fotos.");
      }
    } catch (e: any) {
      setLoading(false);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    clinicalRecordsAll();
  }, []);

  const getDescription = (code: string) => {
    if (code === "PPQ001") {
      return "Colagem da Aparatologia Ortodôntica";
    }
    if (code === "PPQ002") {
      return "Trocar Fio";
    }
    if (code === "PPQ003") {
      return "Manter Fio";
    }
    if (code === "PPQ004") {
      return "Cimentação de banda";
    }
    if (code === "PPQ005") {
      return "Colagem do tubo";
    }
    if (code === "PPQ007") {
      return "Aparelho Complementar";
    }
    if (code === "PPQ008") {
      return "Outros procedimentos";
    }
    if (code === "PPQ009") {
      return "Encaminhamentos";
    }
    if (code === "PPQ015") {
      return "Aparelho Móvel";
    }
    if (code === "PPQ016") {
      return "Realizou a moldagem";
    }
    return null;
  };

  const positionDescriptions = {
    0: "Inferior",
    1: "Superior",
    3: "Superior e Inferior",
  };

  const typeDescriptions: WireDescriptions = {
    1: "12 Redondo",
    2: "14 Redondo",
    3: "16 Redondo",
    4: "18 Redondo",
    5: "20 Redondo",
    6: "16x22 Retangular",
    7: "17x25 Retangular",
    8: "18x25 Retangular",
    9: "19x25 Retangular",
  };

  const wireDescriptions: WireDescriptions = {
    1: "Niti",
    2: "Aço",
    3: "Niti Curva Reversa",
    4: "Aço Curva Reversa",
  };

  return (
    <Container maxWidth="xl">
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <ReturnButton title="Plano de Tratamento" onClick={onClick} />

          <Styled.BoxScroll>
            {records.length > 0 ? (
              records.map((record, index) => (
                <>
                  <Accordion key={index} sx={{ marginTop: "30px" }}>
                    <AccordionSummary
                      expandIcon={<IoIosArrowUp />}
                      aria-controls={String(record.idRecord)}
                      id={String(record.idRecord)}
                      sx={{ fontWeight: "bold" }}
                    >
                      {record.appointment}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Styled.Box>
                        <Styled.BoxClinical>
                          {record.nextAppointment.map((item, index) => {
                            return (
                              <Styled.BoxItens key={index}>
                                <Typography
                                  sx={{ fontWeight: "bold !important" }}
                                >
                                  {getDescription(item.id)}
                                </Typography>

                                {item.position === 0 ||
                                item.position === 1 ||
                                item.position === 3
                                  ? (
                                      <Typography>
                                        {positionDescriptions[item.position]}
                                      </Typography>
                                    ) || ""
                                  : ""}

                                {typeof item.metaData === "object" &&
                                  !Array.isArray(item.metaData) && (
                                    <>
                                      {item.metaData.wire !== undefined && (
                                        <Typography>
                                          Material:{" "}
                                          {wireDescriptions[
                                            item.metaData.wire
                                          ] || ""}
                                        </Typography>
                                      )}

                                      {item.metaData.type !== undefined && (
                                        <Typography>
                                          Número:{" "}
                                          {typeDescriptions[
                                            item.metaData.type
                                          ] || ""}
                                        </Typography>
                                      )}
                                    </>
                                  )}

                                {Array.isArray(item.metaData) &&
                                  item.metaData.length > 0 && (
                                    <>
                                      {item.metaData.map(
                                        (descItem, descIndex) => (
                                          <Typography key={descIndex}>
                                            {descItem.label}
                                          </Typography>
                                        )
                                      )}
                                    </>
                                  )}
                              </Styled.BoxItens>
                            );
                          })}
                        </Styled.BoxClinical>
                      </Styled.Box>
                    </AccordionDetails>
                  </Accordion>
                </>
              ))
            ) : (
              <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
                  Não há ficha clínica para este paciente.
                </Typography>
              </Box>
            )}
          </Styled.BoxScroll>
        </>
      )}
    </Container>
  );
};

export { TreatmentPlan };
