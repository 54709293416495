import React from "react";

import { Button, Container } from "@mui/material";
import { ReturnButton } from "components/ReturnButton";

import * as Styled from "./styles";
import { useNavigate } from "react-router-dom";
import { endService } from "requests/RestService";
import { useAuth } from "shared/providers/auth";
import toast from "react-hot-toast";

const CloseConsult: React.FC<{
  close: () => void;
  idSchedule: number;
}> = ({ close, idSchedule }) => {
  const navigate = useNavigate();
  const { user, setResponses } = useAuth();

  const suspendConsult = async () => {
    const { status, data } = await endService({
      id: user.data.id,
      idSchedule: idSchedule,
    });

    if ([200, 201].includes(status)) {
      setResponses([]);
      localStorage.removeItem("personAttendance");
      navigate("/home");
      close();
    } else {
      toast.error(data.message);
    }
  };
  return (
    <Container maxWidth="xl">
      <ReturnButton
        title="Deseja encerrar o atendimento?"
        subtitle="Todas as informações preenchidas serão perdidas"
        onClick={close}
      />
      <Styled.BoxBtn>
        <Button onClick={suspendConsult}>ENCERRAR</Button>
        <Button variant="outlined" onClick={close}>
          Cancelar
        </Button>
      </Styled.BoxBtn>
    </Container>
  );
};

export { CloseConsult };
