import API from "shared/services/Api";
import { IOrtoResponse } from "shared/types/Printing";

export const getOrtos = async (
  id: string,
  idPac: number,
  idReferrals: number,
  isSchedule: number
): Promise<IOrtoResponse> =>
  API.get(
    `/oc/printing?id=${id}&idPac=${idPac}&idReferrals=${idReferrals}&idSchedule=${isSchedule}`
  );
