import React from "react";

import { Container } from "@mui/material";
import { ClinicalProvider } from "shared/providers/Clinical";
import { Finalize } from "./components";

const Clinical: React.FC = () => {
  return (
    <ClinicalProvider>
      <Container maxWidth="xl">
        <Finalize />
      </Container>
    </ClinicalProvider>
  );
};

export { Clinical };
