import styled from "styled-components";

interface ContainerPicturesProps {
  isDocumentation: boolean;
}

export const ContainerPictures = styled.div<ContainerPicturesProps>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: ${(props) =>
    props.isDocumentation
      ? "80vh"
      : "auto"}; /* altura fixa se for documentação */
  overflow: ${(props) =>
    props.isDocumentation
      ? "scroll"
      : "visible"}; /* scroll se for documentação */
  margin: 25px 0;
  padding: 0 50px;

  a {
    color: #000;
  }
`;

export const BoxImage = styled.div`
  width: 100%;

  div {
    width: 100% !important;
  }

  .catimg {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    cursor: zoom-in;
  }
`;
