/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import * as Styled from "./styles";

import { Box, CircularProgress, Container, Typography } from "@mui/material";
import { ReturnButton } from "components/ReturnButton";
import { useAuth } from "shared/providers/auth";
import { useParams } from "react-router-dom";
import { getAppraisal } from "requests/Appraisal";
import toast from "react-hot-toast";

const AvalitationUser: React.FC<{
  idPacientParam?: number;
  onClick?: () => void;
}> = ({ idPacientParam, onClick }) => {
  const { user } = useAuth();
  const { idPacient } = useParams();
  const [loading, setLoading] = React.useState<boolean>(false);

  const [complaint, setComplaint] = React.useState<string>("");

  const avaliationAll = async () => {
    setLoading(true);

    try {
      setLoading(false);
      const { status, data } = await getAppraisal(
        user.data.id,
        Number(
          idPacientParam && idPacientParam > 0 ? idPacientParam : idPacient
        )
      );

      if ([200, 201].includes(status)) {
        console.log(data);

        setComplaint(data.data.complaint);
      } else {
        toast.error(data.message);
      }
    } catch (e: any) {
      setLoading(false);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    avaliationAll();
  }, []);

  return (
    <Box sx={{ backgroundColor: "white", height: "100%", width: "100%" }}>
      <Container maxWidth="xl">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <ReturnButton title="Avaliação" onClick={onClick} />

            <Styled.ContainerPictures>
              {complaint ? (
                <Styled.BoxDescription>
                  <h5>Queixa principal</h5>

                  <Typography>{complaint}</Typography>
                </Styled.BoxDescription>
              ) : (
                <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                  <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
                    Não há avaliação para este paciente.
                  </Typography>
                </Box>
              )}
            </Styled.ContainerPictures>
          </>
        )}
      </Container>
    </Box>
  );
};

export { AvalitationUser };
