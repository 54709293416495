import styled from "styled-components";

export const Header = styled.div`
  width: 100%;
  background: linear-gradient(86.29deg, #042b7e 49.84%, #00b4e6 96.86%);
  padding: 20px 0;
`;

export const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;

export const FirstLineModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export const Menu = styled.div`
  background-color: #113685;
  padding: 10px 20px;
  display: none;

  svg {
    path {
      color: white;
    }
  }
`;

export const FakeMenu = styled.div`
  background-color: transparent;
  padding: 10px 20px;
  height: 30px;
  width: 30px;

  svg {
    path {
      color: white;
    }
  }

  @media (max-width: 500px) {
    height: 0;
    width: 0;
  }
`;

export const Logo = styled.div``;

export const FakeBox = styled.div`
  svg {
    path {
      color: white;
    }
  }
`;

export const SecondLine = styled.div`
  margin-top: 20px;
  width: 100%;

  .MuiFormControl-root {
    background-color: white;
    border-radius: 10px;
  }

  label {
    background-color: transparent !important;
  }
`;

export const ModalBox = styled.div`
  background-color: #00357b;
  height: 100%;
  width: 100%;
  padding-top: 20px;
  @media (min-width: 1030px) {
    width: 97%;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 55px;
  height: 100%;
  text-align: center;
  margin-top: 150px;

  a {
    text-decoration: none;
    color: white;
    font-size: 34px;
  }
`;
