import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  padding: 30px 0;

  @media (max-width: 500px) {
    .hiddenMobile {
      display: none;
    }
  }
`;

export const CalendarBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-left: 20px;
  align-items: center;

  p {
    color: #00357b;
    font-size: 20px;
    font-weight: 500;
    margin-top: 2px;
    margin-left: 2px;
  }
`;
