import React from "react";
import * as Styled from "./styles";
import {
  TextField,
  Button,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  //Modal,
} from "@mui/material";
import { useAvaliation } from "shared/providers/Avaliation";
import { IAppraisal } from "shared/types/Appraisal";
import { useAuth } from "shared/providers/auth";
import { sendAppraisal } from "requests/Appraisal";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

//import { Odontograma } from "components/Odontograma";

const AvaliationScreen: React.FC = () => {
  const { user } = useAuth();
  const {
    firstQuestion,
    setFirstQuestion,
    firstReason,
    setFirstReason,
    secondQuestion,
    setSecondQuestion,
    care,
    setCare,
    careReason,
    setCareReason,
    careReasonError,
    setCareReasonError,
    periodont,
    setPeriodont,
    periodontReason,
    setPeriodontReason,
    periodontReasonError,
    setPeriodontReasonError,
    cirurgy,
    setCirurgy,
    cirurgyReason,
    setCirurgyReason,
    cirurgyReasonError,
    setCirurgyReasonError,
    description,
    setDescription,
    descriptionError,
    setDescriptionError,
    //setStep,
    //odontogramaCarie,
    // setOdontogramaCarie,
    //odontogramaCirurgy,
    //setOdontogramaCirurgy,
    //odontogramaProblem,
    //setOdontogramaProblem,
    patientObj,
  } = useAvaliation();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState<boolean>(false);

  // const [openCarie, setOpenCarie] = React.useState<boolean>(false);
  //const [openCirurgy, setOpenCirurgy] = React.useState<boolean>(false);
  //const [openProblem, setOpenProblem] = React.useState<boolean>(false);

  const [motivError, setMotivError] = React.useState<string>("");

  const reason =
    (care ? "Carie" : "") +
    (periodont ? "Problema Periodontal" : "") +
    (cirurgy ? "Caso Cirúrgico" : "") +
    (firstReason ? firstReason : "");

  // Remove espaços em branco extras
  const finalReason = reason.trim() || "";

  const obs =
    (care ? careReason : "") +
    (periodont ? periodontReason : "") +
    (cirurgy ? cirurgyReason : "");

  // Remove espaços em branco extras
  const finalObs = obs.trim() || "";

  const payload: IAppraisal = {
    id: user.data.id,
    idSchedule: patientObj.id,
    idPac: patientObj.pac_id,
    data: [
      {
        complaint: description,
        recommendation: firstQuestion === "yes" ? "S" : "N",
        immediate: secondQuestion === "yes" ? 1 : 0,
        ...(finalReason && { reason: finalReason }),
      },
    ],
    ...(finalObs && { obs: finalObs }),
  };

  const sendConsult = async () => {
    setLoading(true);
    const { status, data } = await sendAppraisal(payload);

    if ([200, 201].includes(status)) {
      toast.success(data.message);
      navigate("/home");
      localStorage.removeItem("personAttendance");
    } else {
      toast.error(data.message);
    }

    setLoading(false);
  };

  const handleContinue = () => {
    let valid = true;

    if (firstQuestion === "no") {
      if (firstReason.length < 19) {
        setMotivError("Motivo deve conter mais de 20 caracteres.");
        return false;
      }
    }

    if (description.length < 20) {
      setDescriptionError("A descrição deve conter mais de 20 caracteres.");
      valid = false;
    } else {
      setDescriptionError("");
    }

    if (care && careReason.length === 0) {
      setCareReasonError(
        "Por favor, forneça um motivo para 'Cáries extensas'."
      );
      valid = false;
    } else {
      setCareReasonError("");
    }

    if (periodont && periodontReason.length === 0) {
      setPeriodontReasonError(
        "Por favor, forneça um motivo para 'Problema periodontal'."
      );
      valid = false;
    } else {
      setPeriodontReasonError("");
    }

    if (cirurgy && cirurgyReason.length === 0) {
      setCirurgyReasonError(
        "Por favor, forneça um motivo para 'Caso cirúrgico'."
      );
      valid = false;
    } else {
      setCirurgyReasonError("");
    }

    if (valid) {
      sendConsult();
    }
  };

  return (
    <Styled.Box>
      <Styled.BoxDescription>
        <h5>Queixa principal</h5>
        <TextField
          minRows={5}
          maxRows={5}
          multiline
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
            if (e.target.value.length > 19) {
              setDescriptionError("");
            }
          }}
          error={!!descriptionError}
          helperText={descriptionError}
        />
      </Styled.BoxDescription>

      <Styled.BoxDescription>
        <Typography variant="h5">
          Indicação para Tratamento Ortodôntico?
        </Typography>
        <Styled.BoxButtons>
          <Button
            variant={firstQuestion === "no" ? "contained" : "outlined"}
            onClick={() => setFirstQuestion("no")}
          >
            NÃO
          </Button>
          <Button
            variant={firstQuestion === "yes" ? "contained" : "outlined"}
            onClick={() => setFirstQuestion("yes")}
          >
            SIM
          </Button>
        </Styled.BoxButtons>
      </Styled.BoxDescription>

      {firstQuestion === "no" && (
        <TextField
          label="Motivo"
          value={firstReason}
          onChange={(e) => {
            setFirstReason(e.target.value);
            if (e.target.value.length > 20) {
              setMotivError("");
            }
          }}
          error={!!motivError}
          helperText={motivError}
          fullWidth
          margin="normal"
        />
      )}

      {firstQuestion === "yes" && (
        <div>
          <Styled.BoxDescription>
            <Typography variant="h5">
              Início Imediato do Tratamento Ortodôntico?
            </Typography>
            <Styled.BoxButtons>
              <Button
                variant={secondQuestion === "no" ? "contained" : "outlined"}
                onClick={() => setSecondQuestion("no")}
              >
                NÃO
              </Button>
              <Button
                variant={secondQuestion === "yes" ? "contained" : "outlined"}
                onClick={() => setSecondQuestion("yes")}
              >
                SIM
              </Button>
            </Styled.BoxButtons>
          </Styled.BoxDescription>

          {secondQuestion === "no" && (
            <>
              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={care} onClick={() => setCare(!care)} />
                    }
                    label="Cáries extensas"
                  />
                </FormGroup>
                {care && (
                  <TextField
                    label="Motivo"
                    value={careReason}
                    onChange={(e) => setCareReason(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                    error={!!careReasonError}
                    helperText={careReasonError}
                  />
                )}

                {/*care && (
                  <Button onClick={() => setOpenCarie(true)}>
                    Abrir Odontograma
                  </Button>
                )

                <Modal open={openCarie} onClose={() => setOpenCarie(false)}>
                  <Odontograma
                    onClose={() => setOpenCarie(false)}
                    selected={odontogramaCarie}
                    setSelected={setOdontogramaCarie}
                    subtitle="Cáries Extensas"
                  />
                </Modal>*/}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={periodont}
                        onClick={() => setPeriodont(!periodont)}
                      />
                    }
                    label="Problema periodontal"
                  />
                </FormGroup>
                {periodont && (
                  <TextField
                    label="Motivo"
                    value={periodontReason}
                    onChange={(e) => setPeriodontReason(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                    error={!!periodontReasonError}
                    helperText={periodontReasonError}
                  />
                )}

                {/*periodont && (
                  <Button onClick={() => setOpenProblem(true)}>
                    Abrir Odontograma
                  </Button>
                )

                <Modal open={openProblem} onClose={() => setOpenProblem(false)}>
                  <Odontograma
                    onClose={() => setOpenProblem(false)}
                    selected={odontogramaProblem}
                    setSelected={setOdontogramaProblem}
                    subtitle="Problema periodontal"
                  />
                </Modal>*/}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={cirurgy}
                        onClick={() => setCirurgy(!cirurgy)}
                      />
                    }
                    label="Caso cirúrgico"
                  />
                </FormGroup>
                {cirurgy && (
                  <TextField
                    label="Motivo"
                    value={cirurgyReason}
                    onChange={(e) => setCirurgyReason(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                    error={!!cirurgyReasonError}
                    helperText={cirurgyReasonError}
                  />
                )}

                {/*cirurgy && (
                  <Button onClick={() => setOpenCirurgy(true)}>
                    Abrir Odontograma
                  </Button>
                )

                <Modal open={openCirurgy} onClose={() => setOpenCirurgy(false)}>
                  <Odontograma
                    onClose={() => setOpenCirurgy(false)}
                    selected={odontogramaCirurgy}
                    setSelected={setOdontogramaCirurgy}
                    subtitle="Caso cirúrgico"
                  />
                </Modal>
                */}
              </Styled.BoxIndication>
            </>
          )}
        </div>
      )}

      {(firstQuestion === "no" ||
        secondQuestion === "yes" ||
        secondQuestion === "no") && (
        <Styled.BtnFixed>
          <Button fullWidth onClick={handleContinue} disabled={loading}>
            {loading ? <CircularProgress /> : "Finalizar"}
          </Button>
        </Styled.BtnFixed>
      )}
    </Styled.Box>
  );
};

export { AvaliationScreen };
