import React from "react";

import * as Styled from "./styles";

import { Box, CircularProgress, Container, Typography } from "@mui/material";
import { ReturnButton } from "components/ReturnButton";
import { getDocuments } from "requests/Documentation";
import { useAuth } from "shared/providers/auth";
import { useLocation, useParams } from "react-router-dom";
import { FaDownload } from "react-icons/fa";

import { Image } from "react-grid-gallery";

import { GalleryDocuments } from "components/Gallery";
import toast from "react-hot-toast";

export interface CustomImage extends Image {
  original: string;
}

interface ResponseData {
  [key: string]: string;
}

const Documentation: React.FC<{
  idPacientParam?: number;
  onClick?: () => void;
}> = ({ idPacientParam, onClick }) => {
  const location = useLocation();
  const { user } = useAuth();
  const { idPacient } = useParams();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [objDoc, setObjDoc] = React.useState<string[]>([]);

  const [images, setImages] = React.useState<CustomImage[]>([]);

  const isDocumentation = !location.pathname.includes("documentation");

  const transformDataToArray = (data: ResponseData): string[] => {
    return Object.keys(data)
      .filter((key) => !isNaN(Number(key)))
      .map((key) => data[key]);
  };

  const documentsAll = React.useCallback(async () => {
    setLoading(true);

    try {
      setLoading(false);
      const { status, data } = await getDocuments({
        userId: user.data.id,
        idPac: Number(
          idPacientParam && idPacientParam > 0 ? idPacientParam : idPacient
        ),
      });

      if ([200, 201].includes(status)) {
        const transformedData: string[] = transformDataToArray(data.data);

        const imageExtensions = [
          ".jpg",
          ".jpeg",
          ".png",
          ".gif",
          ".bmp",
          ".svg",
        ];
        const imageLinks = transformedData.filter((link) =>
          imageExtensions.some((extension) => link.endsWith(extension))
        );

        const customImages: CustomImage[] = imageLinks.map((link) => ({
          src: link,
          original: link,
          width: 320,
          height: 212,
          caption: "Orthodontic",
        }));

        setImages(customImages);

        setObjDoc(transformedData);
      } else {
        toast.error(data.message);
      }
    } catch (e: any) {
      setLoading(false);
    }
    setLoading(false);
  }, [idPacient, idPacientParam, user.data.id]);

  const downloadPdf = (pdf: string) => {
    //@ts-ignore
    if (window && window.ReactNativeWebView) {
      //call rn to download
      //@ts-ignore
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          action: "downloadFile",
          value: pdf,
        })
      );
    } else {
      // Se não estiver em React Native, faz o download pelo navegador
      const link = document.createElement("a");
      link.href = pdf;
      link.download = pdf.split("/").pop() || "download.pdf"; // Define o nome do arquivo, com fallback
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  React.useEffect(() => {
    documentsAll();
  }, [documentsAll]);

  return (
    <Container maxWidth="xl">
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <ReturnButton title="Documentação" onClick={onClick} />

          <Styled.ContainerPictures isDocumentation={isDocumentation}>
            {images.length > 0 ? <GalleryDocuments images={images} /> : ""}
            {objDoc.length > 0
              ? objDoc.map((item, index) => {
                  const isPDF = item.endsWith(".pdf");
                  return isPDF ? (
                    <Box key={index} sx={{ margin: "10px" }}>
                      <a
                        href={item}
                        onClick={(e) => {
                          e.preventDefault();
                          downloadPdf(item);
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <FaDownload /> Baixar PDF {index + 1}
                        </Typography>
                      </a>
                    </Box>
                  ) : (
                    <></>
                  );
                })
              : ""}
          </Styled.ContainerPictures>
        </>
      )}
    </Container>
  );
};

export { Documentation };
